import { render, staticRenderFns } from "./spinner.vue?vue&type=template&id=131558aa&scoped=true"
var script = {}
import style0 from "./spinner.vue?vue&type=style&index=0&id=131558aa&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_lodash@4.17.21_prettier@1.19.1_react@18.3.1_vue-template_tb272p6nxnqexgteervofgylhi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "131558aa",
  null
  
)

export default component.exports