import Vue from 'vue';
import VueRouter from 'vue-router';
import Store from '@/store';
import { AdminRole } from '@/utils/FeatureFlags';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    meta: { title: 'Sign In - Hardloop Config' },
    component: () => import(/* webpackChunkName: "SignIn" */ '@/views/index')
  },
  {
    path: '/login',
    name: 'login',
    redirect: { name: 'index' }
  },
  {
    path: '/config',
    component: () => import(/* webpackChunkName: "Config" */ '@/views/config'),
    children: [
      {
        path: '',
        name: 'config',
        redirect: { name: 'config-clinicians' }
      },
      {
        path: 'clinicians',
        name: 'config-clinicians',
        component: () => import(/* webpackChunkName: "Clinicians" */ '@/views/config/clinicians'),
        meta: { title: 'Clinicians - Hardloop Config' }
      },
      {
        path: 'clinicians/:id',
        name: 'config-clinician-edit',
        component: () => import(/* webpackChunkName: "Clinicians" */ '@/views/config/clinicians'),
        meta: { title: 'Clinician Details - Hardloop Config' }
      },
      {
        path: 'appointments',
        name: 'config-appointments',
        component: () => import(/* webpackChunkName: "Appointments" */ '@/views/config/appointment-types'),
        meta: { title: 'Appointment Types - Hardloop Config', admin: true }
      },
      {
        path: 'departments',
        name: 'config-departments',
        component: () => import(/* webpackChunkName: "Departments" */ '@/views/config/departments'),
        meta: { title: 'Departments - Hardloop Config', admin: true }
      },
      {
        path: 'clinician-orders',
        name: 'config-clinician-orders',
        component: () => import(/* webpackChunkName: "CliniciansOrder" */ '@/views/config/clinician-order'),
        meta: { title: 'Clinicians Order - Hardloop Config' }
      },
      {
        path: 'clinician-orders-log',
        name: 'config-clinician-orders-log',
        component: () => import(/* webpackChunkName: "CliniciansOrder" */ '@/views/config/clinician-order-log'),
        meta: { title: 'Clinicians Order Log - Hardloop Config' }
      },
      {
        path: 'filters',
        name: 'config-filters',
        component: () => import(/* webpackChunkName: "Filters" */ '@/views/config/filters'),
        meta: { title: 'Filters - Hardloop Config', admin: true }
      },
      {
        path: 'bookings',
        name: 'config-bookings',
        component: () => import(/* webpackChunkName: "Booking" */ '@/views/config/booking-window'),
        meta: { title: 'Booking Window - Hardloop Config', admin: true }
      },
      {
        path: 'specialties',
        name: 'config-specialties',
        component: () => import(/* webpackChunkName: "Booking" */ '@/views/config/specialties'),
        meta: { title: 'Specialties - Hardloop Config' }
      },
      {
        path: 'insurance-payors',
        name: 'config-insurance-payors',
        component: () => import(/* webpackChunkName: "InsurancePayors" */ '@/views/config/insurance-payors'),
        meta: { title: 'Insurance Payors - Hardloop Config', admin: true }
      },
      {
        path: 'clinician-audit/:id',
        name: 'clinician-audit',
        component: () => import(/* webpackChunkName: "ClinicianAudit" */ '@/views/config/clinician-log'),
        meta: { title: 'Clinician Audit - Hardloop Config' }
      },
      {
        path: 'insurance-payors/:id/plans',
        name: 'config-payor-plans',
        component: () => import(/* webpackChunkName: "InsurancePayorPlans" */ '@/views/config/payor-plans'),
        meta: { title: 'Insurance Payors Plans - Hardloop Config', admin: true }
      },
      {
        path: 'languages',
        name: 'config-languages',
        component: () => import(/* webpackChunkName: "Languages" */ '@/views/config/languages'),
        meta: { title: 'Languages - Hardloop Config' }
      },
      {
        path: 'generic-testimonials',
        name: 'config-generic-testimonials',
        component: () => import(/* webpackChunkName: "GenericTestimonials" */ '@/views/config/generic-testimonials'),
        meta: { title: 'Generic Testimonials - Hardloop Config' }
      },
      {
        path: 'clinician-types',
        name: 'config-clinician-types',
        component: () => import(/* webpackChunkName: "ClinicianTypes" */ '@/views/config/clinician-types'),
        meta: { title: 'Clinician Types - Hardloop Config', admin: true }
      },
      {
        path: 'cancellation-reasons',
        name: 'config-cancellation-reasons',
        component: () => import(/* webpackChunkName: "CancellationReasons" */ '@/views/config/cancellation-reasons'),
        meta: { title: 'Cancellation Reasons - Hardloop Config', admin: true }
      },
      {
        path: 'schools',
        name: 'config-schools',
        component: () => import(/* webpackChunkName: "Schools" */ '@/views/config/schools'),
        meta: { title: 'Schools - Hardloop Config' }
      }
    ]
  },
  {
    path: '/logout',
    name: 'logout',
    redirect: { name: 'index' }
  },
  {
    path: '*',
    name: 'no-found',
    component: () => import(/* webpackChunkName: "NotFound" */ '@/views/no-route-found'),
    meta: { title: 'Not Found' }
  }
];

const router = new VueRouter({
  saveScrollPosition: true,
  transitionOnLoad: true,
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'router-link-active',
  routes
});

const REDIRECT_ROUTES = ['index'];

// Authentication Middleware
router.beforeEach(async (to, from, next) => {
  const IS_REDIRECT_ROUTE = REDIRECT_ROUTES.includes(to.name);
  if (Store.state.publicRoutes.includes(to.name) && !IS_REDIRECT_ROUTE) return next();
  if (window.Cypress) return next();

  try {
    await Store.dispatch('user/authenticate');
    if (to.meta.admin && Vue.prototype.$ld.ready && !Vue.prototype.$ld.flags.state[AdminRole])
      return next({ name: 'config-clinicians' });
    if (IS_REDIRECT_ROUTE) return next({ name: 'config-clinicians' });
    return next();
  } catch (error) {
    if (IS_REDIRECT_ROUTE) return next();
    next({ name: 'index' });
  }
});

router.afterEach((to) => {
  Vue.nextTick(() => {
    document.title = (to.meta && to.meta.title) || 'Hardloop Config';
  });
});

export default router;
