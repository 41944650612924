import { render, staticRenderFns } from "./public.vue?vue&type=template&id=396c16b0"
import script from "./public.vue?vue&type=script&lang=js"
export * from "./public.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.11.1_css-loader@6.11.0_lodash@4.17.21_prettier@1.19.1_react@18.3.1_vue-template_tb272p6nxnqexgteervofgylhi/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports