import Vue from 'vue';
import axios from 'axios';
import store from '@/store';
import Router from '@/router';
import { Auth } from 'aws-amplify';
import { debounce } from 'lodash';

function getErrorObject(error) {
  if (hasRequestError(error)) {
    return { title: 'Request Error', text: 'Try to Refresh the page or sign in again.', type: 'warn' };
  }
  let message = ' Please refresh the page and try again.';
  if (error.response) {
    message = (error.response?.message || error.response?.data?.message || error.message) + message;
    return {
      title: error.response.status,
      text: message,
      type: 'error'
    };
  } else if (error.message) {
    message = (error.message || 'There was an error. ') + message;
    return { title: 'Error', text: message, type: 'error' };
  } else if (error.request) {
    message = (error.request?.responseText || 'Request Error') + message;
    return {
      title: 'Request Error',
      text: message,
      type: 'error'
    };
  } else {
    message = 'There was an error. ' + message;
    return { title: 'Error', text: message, type: 'error' };
  }
}

function hasRequestError(error) {
  const IS_ERROR = error && error.config;
  if (!IS_ERROR) return;

  const NO_RESPONSE = error.response === undefined;
  const IS_GET_METHOD = error.config.method === 'get';
  return IS_ERROR && NO_RESPONSE && IS_GET_METHOD;
}

function handleExpireToken(error) {
  // When session expires redirect to login
  return Auth.signOut().then(() => {
    Router.push({ name: 'index' }).then(() => {
      handleErrorNotification(error);
    });
  });
}

function handleErrorNotification(error) {
  const errorObject = getErrorObject(error);
  const { title, text, type } = errorObject;
  console.error('error', error);

  Vue.notify({
    group: 'app',
    type,
    title,
    text
  });
}

const handleError = debounce(
  (error) => {
    const CODE = error && error.response && error.response.status;
    if (CODE === 401) return handleExpireToken(error);
    handleErrorNotification(error);
  },
  1000,
  { trailing: false, leading: true }
);

const handleSuccess = debounce(
  (response) => {
    if (
      response.config.method === 'patch' ||
      (response.config.method === 'post' && response.config.data.includes('image'))
    ) {
      Vue.notify({
        group: 'app',
        type: 'success',
        text: 'Saved!'
      });
    }
  },
  1000,
  { trailing: false, leading: true }
);

// CREATE MAIN INSTANCE & DEFAULTS SETTINGS
const AXIOS = axios.create({
  baseURL:
    process.env.VUE_APP_USE_LOCAL_SERVER !== 'true'
      ? process.env.VUE_APP_API_BASE_URL
      : process.env.VUE_APP_LOCAL_API_BASE_URL
});

// REQUEST INTERCEPTOR SETTINGS
AXIOS.interceptors.request.use(
  (config) => {
    if (!store.state?.user?.token) return config;
    config.headers.Authorization = `Bearer ${store.state.user.token}`;
    return config;
  },
  (error) => {
    handleError(error);
    return Promise.reject(error);
  }
);

const retryRequest = (originalRequest) => {
  // Reduce retry timeout, if its even necessary
  const TIME = 2000;
  return new Promise((resolve) => {
    setTimeout(() => resolve(AXIOS(originalRequest)), TIME);
  });
};

// RESPONSE INTERCEPTOR SETTINGS
AXIOS.interceptors.response.use(
  (response) => {
    if (store.state.request.retries.some((request) => request.url == response.config.url)) {
      store.commit('deleteRetryRequest', response.config.url);
    }
    handleSuccess(response);
    return response;
  },
  (error) => {
    const { config } = error;
    const status = (config.response && config.response.status) || 502;

    if (status >= 500) {
      if (
        store.state.request.retries.length === 0 ||
        !store.state.request.retries.some((request) => request.url == config.url)
      ) {
        store.commit('createRetryRequest', config.url);
      }

      const RETRY = store.state.request.retries.find((request) => request.url == config.url).count;
      // Retry requests failing with an internal server error (error mostly produced due to BE delay)
      if (RETRY <= 3) {
        store.commit('addRetryRequestCount', config.url);
        return retryRequest(config, RETRY);
      }

      store.commit('deleteRetryRequest', config.url);
      handleError(error);
      return Promise.reject(error);
    } else {
      handleError(error);
      return Promise.reject(error);
    }
  }
);

export { handleSuccess };
export default AXIOS;
