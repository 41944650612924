import Vue from 'vue';
import * as LDClient from 'launchdarkly-js-client-sdk';
import { FlagDefaultMap } from '@/utils/FeatureFlags';

class LDStore {
  constructor(data = {}) {
    this.storeVM = new Vue({ data });
  }

  get state() {
    return this.storeVM.$data;
  }
}

function strMapToObj(strMap) {
  let obj = Object.create(null);
  for (let [k, v] of strMap) {
    obj[k] = v;
  }
  return obj;
}

export default {
  Store: LDStore,
  install(Vue, options) {
    const { clientSideId, user } = options;
    const ldClient = LDClient.initialize(
      clientSideId,
      user,
      (options = {
        bootstrap: 'localStorage'
      })
    );

    const $ld = Vue.observable({
      ldClient,
      flags: new LDStore(strMapToObj(FlagDefaultMap)),
      ready: false,
      get: (key) => {
        const defaultValue = FlagDefaultMap.get(key);
        const value = ldClient.variation(key, defaultValue ? defaultValue : false);
        if (key in $ld.flags.state) {
          $ld.flags.state[key] = value;
        }
        return value;
      }
    });

    ldClient.on('ready', () => {
      let allFlagsResult = ldClient.allFlags();
      $ld.flags = new LDStore({
        ...strMapToObj(FlagDefaultMap),
        ...allFlagsResult
      });
      FlagDefaultMap.forEach((value, key) => {
        $ld.flags.state[key] = ldClient.variation(key, value);
        ldClient.on(`change:${key}`, (settings) => {
          $ld.flags.state[key] = settings;
        });
      });
      $ld.flags.state['ready'] = true;
      $ld.ready = true;
    });

    Vue.prototype.$ld = $ld;
  }
};
