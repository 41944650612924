import Auth from '@/libs/auth';
import Vue from 'vue';

const state = {
  data: null,
  token: ''
};

const actions = {
  async googleLogin() {
    const provider = 'HL-Google';
    Auth.federatedSignIn({ provider });
  },
  logOut({ commit }) {
    return Auth.signOut().then(() => {
      commit('logOut');
    });
  },
  async authenticate({ commit, dispatch }) {
    const USER = await Auth.currentAuthenticatedUser();
    commit('setData', USER);
    await dispatch('setLDUser');
  },
  async setLDUser() {
    if (state.data.signInUserSession?.idToken?.payload?.sub) {
      const newUser = {
        kind: 'user',
        anonymous: false,
        key: state.data.signInUserSession?.idToken?.payload?.sub,
        email: state.data.signInUserSession?.idToken?.payload?.email
      };
      await Vue.prototype.$ld.ldClient.identify(newUser);
    }
  }
};

const mutations = {
  setData(state, payload) {
    state.data = payload;
    state.token = payload.signInUserSession.idToken.jwtToken;
  },
  logOut(state) {
    state.data = null;
  }
};

const getters = {
  isUserLoggedIn: (state) => {
    return state.data !== null;
  },
  email: (state) => {
    if (!state.data) return '';
    const IS_FEDERATED = 'attributes' in state.data;
    if (IS_FEDERATED) return state.data.attributes.email;
    //Note: In case of future federations the 'hl-google' needs to be dynamic
    return state.data.username.split('hl-google_')[1];
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
