import { Auth } from 'aws-amplify';

const port = (window.location.port && `:${window.location.port}`) || '';
const hostname = window.location.hostname;
const protocol =
  process.env.NODE_ENV !== 'production' && process.env.VUE_APP_LOCALHOST_DEV_ENV !== 'true' ? 'http' : 'https';
const redirectSignIn = `${protocol}://${hostname}${port}/login`;
const redirectSignOut = `${protocol}://${hostname}${port}/logout`;

Auth.configure({
  // REQUIRED - Amazon Cognito Identity Pool ID
  identityPoolId: process.env.VUE_APP_AWS_IDENTITYPOOLID,
  // REQUIRED - Amazon Cognito Region
  region: process.env.VUE_APP_AWS_REGION,
  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: process.env.VUE_APP_AWS_USERPOOLID,
  // OPTIONAL - Amazon Cognito Web Client ID
  userPoolWebClientId: process.env.VUE_APP_AWS_USERPOOLWEBCLIENTID,
  // REQUIRED FOR GOOGLE LOGIN
  oauth: {
    domain: process.env.VUE_APP_SOCIAL_LOGIN_DOMAIN,
    scope: ['email', 'openid'],
    redirectSignIn,
    redirectSignOut,
    responseType: 'code'
  },
  // REQUIRED FOR GOOGLE LOGIN
  federationTarget: 'COGNITO_USER_POOLS'
});

export default Auth;
